export enum DayOfWeek {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday
}

export enum MealTime {
  Breakfast,
  Lunch,
  Dinner
}
